<template>
  <div>
    <div class="article-box">
      <div class="article-title">{{ articleInfo.title }}</div>
      <div class="bottom-box">
        <div class="left-box">
          <div class="article-info">留言数{{ articleInfo.commentNum }}.</div>
          <div class="article-info">精选数{{ articleInfo.selectedNum }}</div>
        </div>
        <span class="article-info">{{ articleInfo.createTime | dateFormat }}</span>
      </div>
    </div>
    <div class="blank-box"></div>
    <van-list
      v-model="loading"
      :error.sync="listError"
      error-text="请求失败，点击重新加载"
      finished-text="到底了哦~"
      :finished="finished" @load="getList">
      <article-comment-details
        v-for="(item, idx) in commentList"
        :key="idx" :commentInfo="item"
        @del-success="refreshList"
        @insert-success="refreshList">

      </article-comment-details>
    </van-list>
  </div>
</template>
<script>
import to from "@/utils/to";
import { List } from "vant";
import { getArticle, getCommentDetails } from "@/api/mine";
import ArticleCommentDetails from "./ArticleCommentDetails";
export default {
  components: {
    ArticleCommentDetails,
    [List.name]: List,
  },
  data() {
    return {
      articleId: '',
      articleInfo: '',
      loading: false,
      listError: false,
      finished: false,

      commentList: [],
      pageNum: 1,
    }
  },
  mounted() {
    this.articleId = this.$route.query.articleId
    this.getInfo();
  },
  methods: {
    refreshList() {
      this.pageNum = 1
      this.getList()
    },
    async getInfo() {
      let [err, res] = await to(getArticle(this.articleId));
      if (err) {
        return;
      }

      this.articleInfo = res;
    },
    async getList() {
      this.loading = true;
      let [err, res] = await to(getCommentDetails(this.articleId, this.pageNum, 15));
      this.loading = false;

      if (err) {
        this.listError = true;
        return;
      }

      if (res.rows.length === 0) {
        this.finished = true
        return;
      }

      if (this.pageNum === 1) {
        this.commentList = res.rows;
      } else {
        this.commentList = this.commentList.concat(res.rows);
      }

      this.loading = false;
      this.pageNum = res.pageNum + 1;
    }
  }
};
</script>
<style scoped>
.article-box {
  margin: 20px 16px 20px 16px;
}

.article-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: #1a1a1a;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-bottom: 12px;
}

.bottom-box {
  display: flex;
  justify-content: space-between;
}

.left-box {
  display: flex;
  justify-content: center;
}

.new-comment {
  background: #FFC83E;
  width: 74px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  color: #333333;
  
}

.article-info {
  font-size: 14px;
  color: #999999;
  margin-left: 4px;
}

.blank-box {
  height: 8px;
  background: #F3F7FA;
}
</style>