import { render, staticRenderFns } from "./ArticleCommentDetails.vue?vue&type=template&id=91a0c2f6&scoped=true&"
import script from "./ArticleCommentDetails.vue?vue&type=script&lang=js&"
export * from "./ArticleCommentDetails.vue?vue&type=script&lang=js&"
import style0 from "./ArticleCommentDetails.vue?vue&type=style&index=0&id=91a0c2f6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91a0c2f6",
  null
  
)

export default component.exports