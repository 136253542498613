<template>
  <div class="comment-box">
    <div class="name-box">
      <div>
        <img class="left-headPortrait" :src="commentInfo.avatar" >
        <span class="user-nickname">{{commentInfo.userNickName}}</span>
      </div>
      <div class="right-selected" v-if="!commentInfo.selection" @click="onSelected"><span>精选</span></div>
      <div class="right-selection" v-else @click="onSelected"><span>取消精选</span></div>
    </div>
    <div class="commentContent-box">
      <span class="comment-content">{{ commentInfo.content }}</span
      ><br />
      <span class="createTime">{{ commentInfo.createTime }}</span>
      <span class="reply" @click="onReply" v-if="!replyFontShow">回复</span>
      <div v-if="replyShow">
        <van-field class="reply-box" v-model="inputText" autosize type="textarea" rows="1" :placeholder="'回复' + commentInfo.userNickName"></van-field>
        <div class="button-box">
          <div class="button" @click="onBack">取消</div>
          <div class="button  button-determine" @click="onInit">确定</div>
        </div>
      </div>
      
      <div class="commentReply-box" v-if="show">
      <span class="spanFont">作者回复:</span><br />
      <div class="whole-content">{{commentInfo.reply}}</div>
      <span class="reply-time">{{commentInfo.replyTime}}</span>
      <span class="del" @click="onDel">删除</span><br>
    </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import to from "@/utils/to";
import { articleSelected, insertReply, deleteReply } from "@/api/mine";
import { Field } from "vant";
Vue.use(Field);
export default {
  props: {
    commentInfo: Object,
  },
  data() {
    return {
      replyShow: false,
      inputText: '',
    }
  },
  computed: {
    replyFontShow() {
      return this.commentInfo.reply != null
    },
    show() {
      return this.commentInfo.reply != null
    },
  },
  methods: {
    onSelected() {
      this.commentInfo.selection = !this.commentInfo.selection;
      this.isSelected();
    },
    onReply() {
      this.replyShow = true;
    },
    onBack() {
      this.inputText = '';
      this.replyShow = false;
    },
    onInit() {
      this.insert();
    },
    onDel() {
      this.delete();
    },
    async isSelected() {
      let [err] = await to(articleSelected(this.commentInfo.id));
      if (err) {
        return;
      }
    },
    async insert() {
      if (this.inputText.trim().length <= 0) {
        this.$showFail("请填写回复后提交");
        return;
      }
      this.$showLoading('提交中...')
      
      let [err] = await to(insertReply(this.commentInfo.id, this.inputText))

      this.$hideLoading()

      if (err) {
        this.$showFail("提交失败");
        return;
      }
      this.inputText = '';
      this.replyShow = false;
      this.$emit("insert-success");
    },
    async delete() {
      let [err] = await to(deleteReply(this.commentInfo.replyId));
      if (err) {
        this.$showFail("删除失败");
        return;
      }

      this.$emit('del-success');
    }
  }
}
</script>
<style scoped>
.comment-box {
  margin: 20px 16px 20px 16px;
}

.name-box {
  display: flex;
  justify-content: space-between;
}

.left-headPortrait {
  width: 30px;
  height: 30px;
  vertical-align: middle;
  border-radius: 50%;
}

.user-nickname {
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  margin-left: 8px;
}

.right-selected {
  border: 1px solid #57738f;
  border-radius: 4px;
  font-size: 14px;
  color: #57738f;
  margin-bottom: auto;
  padding: 2px 4px 2px 4px;
  float: left;
}

.right-selection {
  border: 1px solid #999999;
  font-size: 14px;
  border-radius: 4px;
  color: #999999;
  margin-bottom: auto;
  padding: 2px 4px 2px 4px;
  float: left;
}

.commentContent-box {
  margin-left: 36px;
  width: 305px;
  margin-right: 0px;
}

.comment-content {
  font-size: 16px;
  color: #333333;
  line-height: 22px;
}

.createTime {
  font-size: 14px;
  color: #999999;
  line-height: 20px;
  margin-left: 0px;
}

.reply-box {
  border: 1px solid #FFC83E;
  width: 100%;
}

.reply {
  margin-left: 20px;
  font-size: 14px;
  color: #57738F;
}

.button-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 17px;
}

.button {
  margin-left: 20px;
  color: #808080;
  font-size: 14px;
}

.button-determine {
  border: 0.5px solid #999999;
  border-radius: 4px;
  padding: 5px 7px 5px 7px;
}

.reply-time {
  font-size: 14px;
  color: #999999;
}

.del {
  font-size: 14px;
  color: #808080;
  float: right;
}

.spanFont {
  font-size: 14px;
  color: #9A9A9A;
}

.whole-content {
  font-size: 16px;
  color: #333333;
  font-weight: normal;
  word-wrap: break-word
}
</style>